/* eslint-disable max-len */
import React from 'react';
// eslint-disable-next-line css-modules/no-unused-class
import s from './Rating.module.scss';

type Props = {
  percentage: number;
  width: number;
};
/* eslint-disable react/react-in-jsx-scope */
const Star = ({ percentage, width }: Props) => {
  return (
    <div
      style={{ height: width, width }}
      className={`${s.starContainer} PL-rating-star`}
    >
      <div className={`${s.border} PL-rating-star-border`}>
        <svg
          height={'100%'}
          viewBox="18.643 29.967 462.714 440.067"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill="none"
            stroke="black"
            strokeWidth={4}
            d="M 250 29.967 L 330.072 163.02 L 481.357 198.058 L 379.56 315.327 L 392.986 470.034 L 250 409.457 L 107.014 470.034 L 120.44 315.327 L 18.643 198.058 L 169.928 163.02 Z"
          />
        </svg>
      </div>
      <div className={`${s.starBackground} PL-rating-star-background`}>
        <svg
          height={'100%'}
          viewBox="18.643 29.967 462.714 440.067"
          xmlns="http://www.w3.org/2000/svg"
        >
          <mask id="mask">
            <path
              fill="white"
              stroke="black"
              strokeWidth={4}
              d="M 250 29.967 L 330.072 163.02 L 481.357 198.058 L 379.56 315.327 L 392.986 470.034 L 250 409.457 L 107.014 470.034 L 120.44 315.327 L 18.643 198.058 L 169.928 163.02 Z"
            />
          </mask>
          <rect
            mask="url(#mask)"
            width={`${percentage}%`}
            height={'100%'}
            fill="#fccf03"
          />
        </svg>
      </div>
    </div>
  );
};

export default Star;
