/* eslint-disable complexity */
/* eslint-disable sort-keys */
import { ProductListItemProps } from '../common/ProductListItem/models';
import { Product } from '../models/search';

export const webShopManagerProductFields: ProductListItemProps['visibleFields'] =
  [
    { position: 'center', prop: 'brand_name' },
    { position: 'center', prop: 'title' },
    { position: 'center', prop: 'tag', className: 'mb-7' },
    { position: 'center', prop: 'fitment_applicability' },
    { label: 'SKU: ', position: 'center', prop: 'stockid', className: 'mb-7' },
    { label: 'PART #: ', position: 'center', prop: 'dealerid' },
  ];

/*
  This is a custom logic to handle a specific business rules to show products.
  Please see: https://wdsolutions.atlassian.net/browse/WSMSUN-316
*/
function wsmVisibleFieldsBuilder(product: Product) {
  let webShopManagerProductFieldsConfig = [...webShopManagerProductFields];
  const productToMutate = { ...product };
  // AVAILABILITY logic
  if (product.availability === 'Please Call') {
    /*
      show "Please Call" in bold red
      hide PRICING UI
	  */
    webShopManagerProductFieldsConfig = [
      ...webShopManagerProductFieldsConfig,
      { position: 'right', prop: 'availability', className: 'red bold' },
    ];
    productToMutate.sale = null;
    productToMutate.price = null;
  } else if (
    product.availability === 'Out of Stock' ||
    (product.availability === 'Inventory' && product.inventory === 0)
  ) {
    /*
      show "Out of Stock" in bold red
      show PRICING UI
    */
    productToMutate.availability = 'Out of Stock';
    webShopManagerProductFieldsConfig = [
      ...webShopManagerProductFieldsConfig,
      { position: 'right', prop: 'availability', className: 'red bold' },
    ];
  }

  // PRICING
  if (product.sale_hidden === 1) {
    /*
      show linked text "View to see price" in bold blue
      do not show price
    */
    productToMutate.price = null;
    webShopManagerProductFieldsConfig = [
      ...webShopManagerProductFieldsConfig,
      { position: 'right', prop: 'url' },
    ];
  } else if (product.sale > 0 && product.sale < product.price) {
    /*
      show bold red product.sale
      show grey strikethrough product.price
    */
    webShopManagerProductFieldsConfig = [
      ...webShopManagerProductFieldsConfig,
      { position: 'right', prop: 'sale', className: 'red bold' },
      { position: 'right', prop: 'price', className: 'old-price' },
    ];
    if (product.rating_count) {
      webShopManagerProductFieldsConfig.push({
        position: 'right',
        prop: 'rating_count',
      });
    }
  } else if (product.sale === 0 && product.price >= 0) {
    /*
      show bold black product.price
    */
    webShopManagerProductFieldsConfig = [
      ...webShopManagerProductFieldsConfig,
      { position: 'right', prop: 'price', className: 'bold' },
    ];
    if (product.rating_count) {
      webShopManagerProductFieldsConfig.push({
        position: 'right',
        prop: 'rating_count',
      });
    }
  } else if (product.sale && product.price && product.sale === product.price) {
    webShopManagerProductFieldsConfig = [
      ...webShopManagerProductFieldsConfig,
      { position: 'right', prop: 'price' },
    ];
    if (product.rating_count) {
      webShopManagerProductFieldsConfig.push({
        position: 'right',
        prop: 'rating_count',
      });
    }
  } else if (product.sale && product.price && product.sale > product.price) {
    webShopManagerProductFieldsConfig = [
      ...webShopManagerProductFieldsConfig,
      { position: 'right', prop: 'sale' },
    ];
    if (product.rating_count) {
      webShopManagerProductFieldsConfig.push({
        position: 'right',
        prop: 'rating_count',
      });
    }
  }

  webShopManagerProductFieldsConfig = [
    ...webShopManagerProductFieldsConfig,
    { position: 'right', prop: 'remarks' },
    { position: 'right', prop: 'availability_remarks' },
  ];

  if (
    product.has_options &&
    (product.availability === 'Available' ||
      (product.availability === 'Inventory' && product.inventory > 0))
  ) {
    webShopManagerProductFieldsConfig = [
      ...webShopManagerProductFieldsConfig,
      { position: 'right', prop: 'has_options' },
    ];
  }
  // If ratings weren't added because there is no price nor sale, add it
  if (
    !webShopManagerProductFieldsConfig.filter((field) =>
      Object.keys(field).includes('rating_count')
    ).length &&
    product.rating_count > 0
  ) {
    webShopManagerProductFieldsConfig.push({
      position: 'right',
      prop: 'rating_count',
    });
  }

  return {
    visibleFields: cleadVisibleFields(webShopManagerProductFieldsConfig),
    product: productToMutate,
  };
}

export { wsmVisibleFieldsBuilder };

function cleadVisibleFields(
  webShopManagerProductFieldsConfig: ProductListItemProps['visibleFields']
) {
  const fields = {};
  let visibleFields: ProductListItemProps['visibleFields'] = [];
  for (const field of webShopManagerProductFieldsConfig) {
    if (!fields[field.prop]) {
      visibleFields = [...visibleFields, field];
    }
    fields[field.prop] = true;
  }
  return visibleFields;
}
