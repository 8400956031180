import * as React from 'react';
import Star from './Star';
// eslint-disable-next-line css-modules/no-unused-class
import s from './Rating.module.scss';
interface RatingProps {
  value: number;
  className?: string;
  /** Sets pixel width of the stars. They are squares, so it also sets the height. */
  starWidth?: number;
}

const Rating = ({ value, className, starWidth = 15 }: RatingProps) => {
  return (
    <div className={`${className} ${s.container} PL-rating-stars`}>
      <Star width={starWidth} percentage={calculateStarPercentage(1, value)} />
      <Star width={starWidth} percentage={calculateStarPercentage(2, value)} />
      <Star width={starWidth} percentage={calculateStarPercentage(3, value)} />
      <Star width={starWidth} percentage={calculateStarPercentage(4, value)} />
      <Star width={starWidth} percentage={calculateStarPercentage(5, value)} />
      <span className={`PL-rating-text ${s.ratingText}`}>
        {Math.round(value * 10) / 10}
      </span>
    </div>
  );
};

export default Rating;

const calculateStarPercentage = (index: number, stars_amount: number) => {
  if (stars_amount <= index - 1) {
    return 0;
  } else if (stars_amount >= index) {
    return 100;
  }
  return (stars_amount - (index - 1)) * 100;
};
