import classnames from 'classnames';
import * as React from 'react';
import ListControls from '../../components/ListControls';
import { ListControlsProps } from '../../components/ListControls/models';
import Pagination from '../../components/Pagination';
import LoadingIndicator from '../../components/LoadingIndicator/LoadingIndicator';
import styles from './list.scss';
/**
 * These properties might be present on the
 * product list response, depending on per
 * customer configuration or query result
 * like the exact part match
 */
export interface ProductListMetadata {
  showSimilarPartNumberMatch?: boolean;
  exactMatchInResult?: boolean;
  exactPartMatch?: boolean;
}
export interface ListProps<T>
  extends Omit<ListControlsProps, 'itemsPerPageOptions'> {
  /**
   * The list to be rendered.
   */
  list: Array<T>;
  /**
   * Element to be rednered as list
   */
  children: React.ReactNode;
  /**
   * The selected page.
   */
  currentPage?: number;
  /**
   * Total pages when pagination is enabled, this number must be sent when `showPagination` property is TRUE.
   */
  totalPages?: number;
  /**
   * If you want to show the pagination component, send this flag as TRUE.
   */
  showPagination?: boolean;

  /**
   * Callback fired when the page is changed after clicking a page number in the pagination component.
   */
  onPageChanged?: (page: number) => void;
  /**
   * To show a loading indicator when `styled` and `isLoading` properties are  TRUE.
   */
  showLoadingIndicator?: boolean;
  /**
   * Callback to render any component when there are no results (when the list is empty).
   */
  renderNoResults?: () => JSX.Element;
  /**
   * Flag to indicate when the list is loading data from API.
   */
  isLoading?: boolean;
  /**
   * Flag to show the list controls.
   */
  showControls?: boolean;
  /**
   * Callback fired when user clicks a list item.
   */
  onItemClick?: (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    data: T
  ) => void;
  producListMetaData?: ProductListMetadata;
}

function List<T>({
  children,
  list,
  totalPages = 1,
  currentPage = 1,
  onPageChanged = () => null,
  className = '',
  styled,
  orientation = 'horizontal',
  onLayoutChange,
  showPagination = false,
  showLoadingIndicator = false,
  renderNoResults,
  isLoading,
  showControls,
  itemsPerPage,
  onItemsPerPageChange,
  onSortChange,
  sortOptions,
  selectedSort,
}: ListProps<T>) {
  const noResults = () => {
    if (!isLoading) {
      return renderNoResults ? (
        renderNoResults()
      ) : (
        <h1 className="Sui-List--no-results">No results</h1>
      );
    }

    return null;
  };

  return (
    <div className={classnames(styles.root, className)}>
      {showControls ? (
        <ListControls
          orientation={orientation}
          onLayoutChange={onLayoutChange}
          onItemsPerPageChange={(value) => onItemsPerPageChange(value)}
          itemsPerPage={itemsPerPage}
          styled={styled}
          sortOptions={sortOptions}
          onSortChange={onSortChange}
          selectedSort={selectedSort}
        />
      ) : null}
      {showLoadingIndicator && styled && isLoading ? (
        <LoadingIndicator type="linear" className={styles.loadingIndicator} />
      ) : null}

      {list.length ? (
        <>
          <div
            className={classnames(
              styles.listContainer,
              {
                [styles.isBusy]: styled && isLoading,
                [styles.listHorizontal]: orientation === 'horizontal',
                [styles.listContainerStyled]: styled,
                'Sui-List--horizontal': orientation === 'horizontal',
              },
              'Sui-List--container'
            )}
            data-testid="listContainer"
          >
            {children}
          </div>
          {showPagination ? (
            <Pagination
              className={styles.pagination}
              totalPages={totalPages}
              currentPage={currentPage}
              onChangePage={onPageChanged}
            />
          ) : null}
        </>
      ) : (
        noResults()
      )}
    </div>
  );
}

export default List;
