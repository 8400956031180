/* eslint-disable complexity */
import classnames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import useEventCallback from '../../common/customHooks/useEventCallback';
import { env } from '../../config';
import { Error } from '../../models/generic';
import {
  FITMENT_SELECTOR_STORAGE_KEY,
  OPTIONAL_QUALIFIERS_FOR_URL_STORAGE_KEY,
} from '../../utils/constants';
import {
  convertSelectedDataToArray,
  validateSelectedValues,
} from '../../utils/fitmentUtils';
import restFactory from '../../utils/restFactory';
import Alert from '../Alert';
import {
  FitmentSelectorProps,
  FitmentLabelEntity,
  SelectedValues,
} from '../FitmentSelector/models';
import FitmentSelectorWrapper from '../FitmentSelectorWrapper';
import { ProductListResponse } from '../ProductListWrapper/models';
import { getSelectedFitment } from '../PartslogicFitmentSelectorWrapper/ParslogicFitmentSelectorWrapper';
import {
  transformOptionalLabelsAndData,
  getInitialVq,
} from '../PartslogicSearchPage/utils';
import { FitmentSelectorVerifierProps } from './models';
import styles from './styles/fitmentVerifier.scss';
import { isEqual } from 'lodash';

interface VerifyFitmentResponse {
  valid: boolean;
}
interface AlertType {
  type: '' | 'success' | 'error' | 'aq_not_set';
  visible: boolean;
}

const FitmentSelectorVerifier = ({
  selectedValues,
  showMoreText,
  onShowMoreBtnClick,
  title,
  alertSuccessTitle = 'FITMENT VERIFIED FOR:',
  alertErrorTitle = "DOESN'T FIT:",
  alertButtonText = 'Change vehicle',
  alertButtonAqNotSetText = 'Select additional attributes',
  alertErrorAqNotSetTitle = 'Additional attributes required to verify fitment',
  onDoesntFit,
  onSuccess,
  doesntFitButtonText,
  alertFill,
  showAlertIcon,
  productId,
  sku,
  onDataLoaded,
  qualifiersStatus,
  optionalLabelsTitle = 'Add optional details:',
  includeSkus,
  ...fitmentWrapperProps
}: FitmentSelectorVerifierProps) => {
  const localStorageOptionalRealIdQualifiers =
    JSON.parse(
      localStorage.getItem(OPTIONAL_QUALIFIERS_FOR_URL_STORAGE_KEY) || '{}'
    )?.[0] || {};
  const isMounted = useRef(true);
  const [alert, setAlert] = useState<AlertType>({ type: '', visible: false });
  const [isFitmentDataLoaded, setIsFitmentDataLoaded] = useState(false);
  const [selectedFitmentData, setSelectedFitmentData] =
    useState<SelectedValues>(selectedValues || {});
  const [fitmentError, setFitmentError] = useState<Error>();
  const [fitmentKey, setFitmentKey] = useState<number>(0);
  const labels = useRef([]);
  const labelsData = useRef({});
  const [optionalLabels, setOptionalLabels] = React.useState<
    FitmentLabelEntity[]
  >([]);
  const [optionalLabelsData, setOptionalLabelsData] = React.useState<
    FitmentSelectorProps['optionalLabelsData']
  >({});

  useEffect(() => {
    const updateFitment = (e: CustomEvent) => {
      const newFitment = e.detail.fitment;
      if (!isEqual(newFitment, selectedFitmentData)) {
        setSelectedFitmentData(newFitment);
      }
    };
    window.addEventListener('PL_FITMENT_CHANGED', updateFitment);

    return () => {
      window.removeEventListener('PL_FITMENT_CHANGED', updateFitment);
    };
  }, []);

  useEffect(
    () => () => {
      isMounted.current = false;
    },
    []
  );

  const getSelectedFitmentFromLocalStorage = (labels) => {
    const savedFitment =
      JSON.parse(
        localStorage.getItem(FITMENT_SELECTOR_STORAGE_KEY) || '{}'
      )?.[0] || '';

    const selectedFitmentObj = getSelectedFitment(labels, savedFitment) || {};

    return selectedFitmentObj;
  };

  useEffect(() => {
    if (selectedFitmentData !== selectedValues) {
      setSelectedFitmentData(selectedValues);
      if (alert.visible) {
        const validLabels = validateSelectedValues(
          selectedValues,
          labelsData.current,
          labels.current
        );
        const areLabelsValid = areLabelValuesValid(labels.current, validLabels);
        setAlert({ type: areLabelsValid ? 'success' : 'error', visible: true });
      }
    }
  }, [selectedValues]);

  useEffect(() => {
    (async () => {
      const savedFitment =
        JSON.parse(
          localStorage.getItem(FITMENT_SELECTOR_STORAGE_KEY) || '{}'
        )?.[0] || '';

      const productsResponse = await restFactory.get<ProductListResponse>(
        `${env.API_URL}/products`,
        {
          fitment: savedFitment,
          includeSkus,
          limit: 1,
          page: 1,
          productId,
          sku,
        }
      );

      const [optionalLabels, optionalData] = transformOptionalLabelsAndData(
        productsResponse?.vehicleQualifiers
      );

      if (qualifiersStatus === 'enabled') {
        setOptionalLabels(optionalLabels);
        setOptionalLabelsData(optionalData);
      }
    })();
  }, []);

  const onFitmentDataLoaded = useEventCallback(
    async (
      _labels: FitmentLabelEntity[],
      _optionalLabels: FitmentLabelEntity[],
      _labelsData: FitmentSelectorProps['labelsData']
    ) => {
      onDataLoaded?.(_labels, _optionalLabels, _labelsData);
      if (!isMounted.current) return;
      setFitmentError(null);

      labels.current = [..._labels];
      labelsData.current = _labelsData;

      let currentSelectedFitment = selectedFitmentData || {};
      if (
        !Object.keys(selectedFitmentData || {}).length &&
        labels.current.length
      ) {
        currentSelectedFitment = getSelectedFitmentFromLocalStorage(
          labels.current
        );
        setSelectedFitmentData(currentSelectedFitment);
        if (Object.keys(currentSelectedFitment || {}).length) {
          setFitmentKey(fitmentKey + 1);
        } else {
          setIsFitmentDataLoaded(true);
        }
      }
      if (!Object.keys(currentSelectedFitment).length) {
        setIsFitmentDataLoaded(true);
      }

      if (
        [..._labels].length ===
          Object.keys(currentSelectedFitment || {}).length &&
        Object.keys(_labelsData).length === _labels.length
      ) {
        const selectedLabels = Object.keys(
          validateSelectedValues(currentSelectedFitment, _labelsData, _labels)
        ).length;
        if (!Object.keys(currentSelectedFitment).length && !selectedLabels) {
          setAlert({ type: '', visible: false });
        } else if (Object.keys(labels.current).length > selectedLabels) {
          setAlert({ type: 'error', visible: true });
        } else if (Object.keys(labels.current).length === selectedLabels) {
          if (sku || productId) {
            const aqs = productId
              ? {}
              : converAqsToQuery(localStorageOptionalRealIdQualifiers);
            verifyFitmentWithProduct(
              _labels,
              _labelsData,
              currentSelectedFitment,
              aqs
            );
          } else {
            setAlert({ type: 'success', visible: true });
          }
        }
      } else if (
        // This conditional only happens when user hasn't changed the initial selection (first render)
        // and there is not enough data for the fitment
        selectedValues === currentSelectedFitment &&
        Object.keys(selectedValues || {}).length &&
        [..._labels].length !== Object.keys(currentSelectedFitment).length
      ) {
        setAlert({ type: 'error', visible: true });
      }
    },
    [selectedFitmentData, productId]
  );

  const verifyFitmentWithProduct = async (
    labels,
    labelsData,
    selectedData,
    aqs = {}
  ) => {
    const fitmentStr = convertSelectedDataToArray(
      labels,
      selectedData,
      labelsData
    );
    // initial check if aqs are not set
    if (!hasAqAndSelected()) {
      setAlert({
        type: 'aq_not_set',
        visible: true,
      });
      return;
    }
    const options = {
      fitment: fitmentStr.map((item) => item.value).join('|'),
      productId,
      sku,
      ...aqs,
    };
    const response = await restFactory.get<VerifyFitmentResponse>(
      `${env.API_URL}/fitment/check`,
      options
    );

    setAlert({
      type: response.valid ? 'success' : 'error',
      visible: true,
    });
    if (onSuccess) {
      onSuccess(fitmentStr.map((item) => item.value).join('|'));
    }
    return;
  };

  const onSubmit = (values, optValues) => {
    const areAllLabelsSelected = areLabelValuesValid(labels.current, values);
    const aqs = productId ? {} : converAqsToQuery(optValues);
    if (!hasAqAndSelected()) {
      setAlert({
        type: 'aq_not_set',
        visible: true,
      });
      return;
    }
    if (sku || productId) {
      verifyFitmentWithProduct(
        labels.current,
        labelsData.current,
        selectedFitmentData,
        aqs
      );
    } else if (!fitmentWrapperProps.autocommit || areAllLabelsSelected) {
      setAlert({
        type: areAllLabelsSelected ? 'success' : 'error',
        visible: true,
      });
    }
    fitmentWrapperProps?.onSubmit?.(values);
  };

  const alertClick = () => {
    setAlert({ type: '', visible: false });
    setIsFitmentDataLoaded(true);
  };

  const converAqsToQuery = (aqObject) => {
    const aqs = {};
    Object.keys(aqObject)?.forEach((v) => {
      aqs[`aq[${v}]`] = aqObject[v];
    });
    if (Object.keys(aqs).length === 0) {
      return { 'aq[undefined]': null };
    }
    return aqs;
  };

  const getAlertErrorMessage = () => {
    switch (alert.type) {
      case 'error':
        return alertErrorTitle;
      case 'success':
        return alertSuccessTitle;
      case 'aq_not_set':
        return alertErrorAqNotSetTitle;
      default:
        return '';
    }
  };
  /**
   * Needs to have qualifiersStatus = 'enabled'
   * AQ's data are loaded
   * has selected at least one AQ
   * @returns boolean
   */
  const hasAqAndSelected = (): boolean => {
    const aq = getInitialVq();
    if (
      Object.keys(aq).length === 0 &&
      qualifiersStatus === 'enabled' &&
      Object.keys(optionalLabelsData).length > 0
    ) {
      return false;
    }
    return true;
  };
  // When the fitment wrapper changes, the vqs are updated inside, this updates the main wrapper too
  const handleQualifiersChange = (obj) => {
    setOptionalLabels(obj['optionalLabels']);
    setOptionalLabelsData(obj['optionalData']);
  };
  return (
    <div
      className={classnames(styles.root, 'Sui-FitmentSelectorVerifier--root')}
    >
      <Alert
        className={styles.alert}
        type={
          alert.type === 'error' || alert.type === 'aq_not_set'
            ? 'error'
            : 'success'
        }
        title={getAlertErrorMessage()}
        text={fitmentToString(
          labels.current,
          selectedFitmentData,
          labelsData.current
        )}
        onClick={alertClick}
        buttonText={
          alert.type === 'aq_not_set'
            ? alertButtonAqNotSetText
            : alertButtonText
        }
        onSecondaryButtonClick={onDoesntFit || null}
        secondaryButtonText={doesntFitButtonText || null}
        fill={alertFill}
        showIcon={showAlertIcon}
        styled={fitmentWrapperProps.styled}
        style={{ display: !alert.visible ? 'none' : '' }}
      />
      <div
        data-testid="fitment-verifier-selectors"
        style={{ display: alert.visible || !isFitmentDataLoaded ? 'none' : '' }}
      >
        {title && (
          <p
            className={classnames(
              styles.fitmentTitle,
              'Sui-FitmentSelectorVerifier--title'
            )}
          >
            {title}
          </p>
        )}
        <div className={styles.fitment}>
          <FitmentSelectorWrapper
            productId={productId}
            key={fitmentKey}
            {...fitmentWrapperProps}
            onSubmit={onSubmit}
            onChange={(_, values) => {
              setSelectedFitmentData(values || {});
              fitmentWrapperProps?.onChange?.(_, values);
            }}
            onDataLoaded={onFitmentDataLoaded}
            onError={(err) => {
              setIsFitmentDataLoaded(true);
              setFitmentError(err);
            }}
            selectedValues={selectedFitmentData}
            optionalLabels={optionalLabels}
            optionalLabelsData={optionalLabelsData}
            optionalLabelsTitle={optionalLabelsTitle}
            qualifiersStatus={qualifiersStatus}
            sku={sku}
            onQualifiersChange={handleQualifiersChange}
            enableAutoSelect={true}
          />
          {showMoreText && onShowMoreBtnClick && !fitmentError ? (
            <button
              className={styles.searchVehicleBtn}
              onClick={onShowMoreBtnClick}
            >
              {showMoreText}
            </button>
          ) : null}
        </div>
      </div>
    </div>
  );
};

function fitmentToString(
  labels: FitmentLabelEntity[],
  selectedFitmentData: SelectedValues,
  labelsData: FitmentSelectorProps['labelsData']
) {
  let text = '';
  if (
    labels.length &&
    Object.keys(selectedFitmentData || {}).length &&
    Object.keys(labelsData).length
  ) {
    for (const item of labels) {
      const labelValue = selectedFitmentData[item.name];
      if (labelValue) {
        const labeldDataMatch = (labelsData[item.name] || []).find(
          (item) =>
            String(item.id).toLowerCase() === String(labelValue).toLowerCase()
        );
        if (!labeldDataMatch) {
          text =
            'There is an error on your Fitment selection, please try again.';
          break;
        }
        text += ` ${labeldDataMatch.name}`;
      }
    }
  }
  return text.trim();
}

function areLabelValuesValid(
  labels: FitmentLabelEntity[],
  selectedValues: SelectedValues
) {
  return (
    Object.keys(labels).length ===
    Object.values(selectedValues).filter((value) => value).length
  );
}

export default FitmentSelectorVerifier;

export function getFitmentQueryStr(fitment: string) {
  const regex = / /g;
  return fitment.replace(regex, '|');
}
